{
  const parentElement = '.tr-mailchimp-popup';

  const setCookie = (name, value, days) => {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  const getCookie = (name) => {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const eraseCookie = (name) => {
    document.cookie = name + '=; Max-Age=-99999999;';
  };

  const handleAcceptCookies = (name, content, days) => {
    setCookie(name, content, days);
    document.querySelector(parentElement).classList.add('hidden');
  };

  const init = () => {
    const cookieDeclineName = 'treat-no-newsletter';
    const cookieDeclineText = 'declinedNewsletter';
    const cookieDeclineDays = 2;
    const checkDeclineCookie = getCookie(cookieDeclineName);

    const cookieAcceptName = 'treat-newsletter';
    const cookieAcceptText = 'AcceptedNewsletter';
    const cookieAcceptDays = 100;
    const checkAcceptCookie = getCookie(cookieAcceptName);

    document
      .getElementById('closeNewsletter')
      .addEventListener(`click`, () =>
        handleAcceptCookies(
          cookieDeclineName,
          cookieDeclineText,
          cookieDeclineDays
        )
      );

    document
      .getElementById('acceptNewsletter')
      .addEventListener(`click`, () =>
        handleAcceptCookies(
          cookieAcceptName,
          cookieAcceptText,
          cookieAcceptDays
        )
      );

    if (checkDeclineCookie || checkAcceptCookie) {
      document.querySelector(parentElement).classList.add('hidden');
    }
  };

  init();
}
