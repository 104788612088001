const { filter } = require('lodash');

{
  let filters = {
    search: '',
    selectedTypes: [],
    product: '',
  };

  let hidden = [];

  const handleInputSearch = (e) => {
    const input = e.currentTarget.value;
    filters['search'] = input.toLowerCase();

    filterItems();
  };

  const handleCheckbox = (e) => {
    const selectedType = e.currentTarget.name;

    if (e.currentTarget.checked) {
      filters['selectedTypes'].push(selectedType);
    } else {
      const index = filters['selectedTypes'].indexOf(selectedType);
      filters['selectedTypes'].splice(index, 1);
    }

    filterItems();
  };

  const handleSelect = (e) => {
    filters['product'] = e.currentTarget.value;

    console.log(filters);

    filterItems();
  };

  const filterItems = () => {
    const recipes = document.querySelectorAll('.tr-recipe');

    recipes.forEach(function (recipe) {
      const recipeTitle = recipe.querySelector('p').innerHTML.toLowerCase();
      const recipeType = recipe.dataset.type;
      const recipeProduct = recipe.dataset.product;

      let searchFilter = filters['search'];
      let typeFilter = filters['selectedTypes'];
      let productFilter = filters['product'];

      if (
        !recipeTitle.includes(searchFilter) ||
        (!typeFilter.some((r) => recipeType.includes(r)) &&
          typeFilter.length > 0) ||
        (!recipeProduct.includes(productFilter) && productFilter !== 'all')
      ) {
        recipe.classList.add('hidden');
      } else {
        recipe.classList.remove('hidden');
      }
    });

    checkEmptyState();
  };

  const checkEmptyState = () => {
    const items = Array.from(document.querySelectorAll('.tr-recipe')).length;
    const hidden = Array.from(
      document.getElementsByClassName('tr-recipe hidden')
    ).length;

    if (items) {
      if (hidden === items) {
        document.querySelector('.emptyState').classList.remove('hidden');
      } else {
        document.querySelector('.emptyState').classList.add('hidden');
      }
    }
  };

  const init = () => {
    // chech if on right page
    if (document.querySelector('.tr-recipes')) {
      document
        .getElementById('trSearch')
        .addEventListener(`keyup`, handleInputSearch);

      Array.from(
        document.querySelectorAll('.tr-checkbox')
      ).forEach((checkbox) =>
        checkbox.addEventListener(`change`, handleCheckbox)
      );

      const productSelect = document.getElementById('products');

      Array.from(document.querySelectorAll('.tr-recipe')).forEach((recipe) => {
        const option = document.createElement('option');
        option.classList.add('tr-recipe__option');
        option.text = recipe.dataset.product;
        option.value = recipe.dataset.product;
        productSelect.add(option);
      });

      const options = document.querySelectorAll('tr-recipe__option');

      if (options) {
        console.log('tzijn der');
        // Array.from(options).forEach((option) =>
        //   option.addEventListener(`change`, handleSelect)
        // );
        productSelect.addEventListener(`change`, handleSelect);
      }
    }
  };

  init();
}
