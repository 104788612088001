{
  const nav = document.querySelector(`.tr-nav`);
  const body = document.querySelector(`body`);

  const handleOpenMenu = (e) => {
    e.currentTarget.classList.toggle('is-active');
    nav.classList.toggle('tr-nav--active');
    body.classList.toggle('mobile__nav__open');
  };

  const init = () => {
    document
      .getElementById(`openNav`)
      .addEventListener(`click`, handleOpenMenu);
  };

  init();
}
