import './_cookieBanner';
import './_responsiveNav';
import './_newsletterCookies';
import './_recipeFilter';

{
  const init = () => {
    // document.getElementById('year').innerHTML = new Date().getFullYear();
  };
  init();
}
